<template>
  <Toast/>
  <form v-if="!is2faPage" class="centered">
    <div class="card">
      <h5>IxArma {{ labels.login }}</h5>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col">
          <label>{{ labels.login }}</label>
          <br/>
          <InputText
              id="login"
              ref="loginInput"
              v-model="auth.username"
              type="text"
              @keyup.enter="handleLogin"
          />
        </div>
        <div class="p-field p-col">
          <label>{{ labels.password }}</label>
          <br/>
          <InputText
              id="password"
              v-model="auth.password"
              type="password"
              @keyup.enter="handleLogin"
          />
        </div>
      </div>
      <Button
          :label="labels.login"
          class="p-col-12"
          type="button"
          @click="handleLogin"
      />
    </div>
  </form>
  <div v-else class="centered">
    <Card style="width: 25rem; margin-bottom: 2em; text-align: center">
      <template #title>{{ labels.twoFactorAuth }}</template>
      <template #content>
        <p v-if="qrCodePath">
          {{ labels.scanForAuthorize }}
        </p>
        <p v-if="qrCodePath">
          {{ labels.dontHaveAccount }}
        </p>
        <div
            v-if="qrCodePath"
            style="display: flex; flex-direction: column; align-items: center"
        >
          <div
              style="
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              align-items: center;
              margin-bottom: 20px;
              width: 100%;
            "
          >
            <Button
                class="p-0 p-button-outlined p-button-success"
                @click="handleOpenLink('GooglePlay')"
            >
              <img
                  alt="google_play"
                  src="./../../assets/google-play.png"
                  style="width: 10px; height: 10px; margin: 0 5px 0 0"
              />
              <span class="px-3">Google Play</span>
            </Button>
            <p style="margin: 0">or</p>
            <Button
                class="p-0 p-button-outlined"
                @click="handleOpenLink('AppStore')"
            >
              <img
                  alt="app_store"
                  src="./../../assets/app-store.png"
                  style="width: 10px; height: 10px; margin: 0 5px 0 0"
              />
              <span class="px-3">App Store</span>
            </Button>
          </div>
          <img v-if="qrCodePath" :src="qrCodePath" alt="qr_code"/>
        </div>
        <p v-if="!qrCodePath">
          {{ labels.enterAuthCode }}
        </p>
        <div
            style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          <div style="margin: 20px 0 0 0">
            <InputText
                id="verificationCode"
                v-model="auth.verificationCode"
                class="p-col-12"
                @keyup.enter="loginWithCode"
            />
            <Button
                :label="labels.verify"
                class="p-col-12 p-mt-2"
                type="button"
                @click="loginWithCode"
            />
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import {generateQrUrl, getGlobalColumnsOrderPermission, login} from "@/api/auth";
import {loginLocale} from "@/enums/calendar-locale.enum";

export default {
  name: "login",
  data() {
    return {
      auth: {
        username: "",
        password: "",
        verificationCode: "",
      },
      is2faPage: false,
      qrCodePath: "",
      labels: {},
      locale: "",
    };
  },
  mounted() {
    document.getElementById("login").focus();
    this.locale = process.env.VUE_APP_LOGIN_LOCALE || "DE";
    this.labels = loginLocale[this.locale];
  },
  methods: {
    getToast(constant, message, type = "error") {
      return this.$toast.add({
        severity: type,
        summary: loginLocale[this.locale].errors[message].summary,
        detail: loginLocale[this.locale].errors[message].detail,
        life: 3000,
      });
    },
    handleLogin() {
      localStorage.setItem("login", this.auth.username);
      login({username: this.auth.username, password: this.auth.password})
          .then((res) => {
            if (res.data.token && res.data.token.length > 0) {
              this.processToken(res);
            } else if (res.status === 200) {
              this.qrCodePath = "";
              this.is2faPage = true;
              setTimeout(function () {
                document.getElementById("verificationCode").focus();
              });
            }
          })
          .catch((err) => {
            if (err.response.data.reason.includes("INVALID_VERIFICATION_CODE")) {
              this.qrCodePath = "";
              this.is2faPage = true;
              setTimeout(function () {
                document.getElementById("verificationCode").focus();
              });
            }

            if (err.response.data.reason.includes("INVALID_CREDENTIALS")) {
              this.getToast(this.locale, "INVALID_CREDENTIALS");
            }

            if (err.response.status === 428) {
              generateQrUrl(this.auth)
                  .then((response) => {
                    if (response.status === 200) {
                      this.qrCodePath = response.data;
                      this.is2faPage = true;
                      setTimeout(function () {
                        document.getElementById("verificationCode").focus();
                      });
                    }
                  })
                  .catch((err) => {
                    if (
                        err.response.data.reason.includes("QR_CODE_ALREADY_ISSUED")
                    ) {
                      this.qrCodePath = "";
                      this.is2faPage = true;
                      setTimeout(function () {
                        document.getElementById("verificationCode").focus();
                      });
                    }
                  });
            }
          });
    },
    loginWithCode() {
      login(this.auth)
          .then((response) => {
            if (response.status !== 401) {
              this.processToken(response);
            }
          })
          .catch((err) => {
            if (err.response.data.reason.includes("INVALID_VERIFICATION_CODE"))
              return this.getToast(this.locale, "INVALID_VERIFICATION_CODE");
          });
    },
    processToken(response) {
      response = response.data;
      localStorage.setItem("authtoken", response.token);

      localStorage.setItem(
          "user",
          JSON.stringify({
            id: response.id,
            name: response.name,
            surname: response.surname,
            authorities: response.authorities,
            orgId: response.orgId,
          })
      );

      localStorage.setItem("lang", response.language);
      if (response.orgId) {
        localStorage.setItem("orgId", response.orgId);
      }

      getGlobalColumnsOrderPermission(response.token).then(({status}) => {
        if (status === 200) {
          localStorage.setItem("GCOP", 'true');
        } else {
          localStorage.removeItem("GCOP");
        }
        this.redirectToHome(response)
      }).catch(() => {
        localStorage.removeItem("GCOP");
        this.redirectToHome(response)
      })
    },
    handleOpenLink(linkType) {
      switch (linkType) {
        case "AppStore":
          return window.open("https://www.apple.com/app-store/", "_blank");
        case "GooglePlay":
          return window.open("https://play.google.com/", "_blank");
        default:
          return;
      }
    },
    redirectToHome(response) {
      delete response.token;
      window.location.href = "/";
    }
  },
};
</script>
<style scoped>
.centered {
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
